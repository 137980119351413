import React from "react";

import "./Home.scss";

import AppView from "../../components/Layout/AppView";
import AppHeader from "../../components/Layout/AppHeader";
import HomePageNotifications from "../../components/HomePageNotifications";
import QuickActionsGrid from "./fragments/QuickActionsGrid";
import RecentTransactions from "./fragments/RecentTransactions";
import AppointmentsList from "./fragments/AppointmentsList";

const HomePage = () => {
  return (
    <AppView>
      <AppHeader />
      <HomePageNotifications />

      {/*  */}

      <div className='HomeLayout'>
        <QuickActionsGrid />
        <RecentTransactions />
        <AppointmentsList />
      </div>
    </AppView>
  );
};

export default HomePage;
