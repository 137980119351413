import React from "react";

import "./RecentTransactions.scss";

const RecentTransactions = () => {
  const transactions = [];
  return (
    <div className='RecentTransactions HomeLayoutCard vlz-12'>
      <div className='__Header'>
        <div className='__title'>Recent transactions</div>
        <div className='__options'></div>
      </div>
      {!!transactions.length && <div className='TransactionsList'></div>}
      {!transactions?.length && (
        <div className='NoTransactionsMessage'>No transactions available to display</div>
      )}
    </div>
  );
};

export default RecentTransactions;
