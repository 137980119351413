import React from "react";

import "./Button.scss";

const VlButton = ({ children, className, click, ...rest }: any) => {
  const handleButtonClick = (event: any) => {
    if (click) click(event);
  };

  return (
    <button {...rest} className={`VlButton ${className || ""}`} onClick={handleButtonClick}>
      {children}
    </button>
  );
};

export default VlButton;
