import React from "react";
import AppView from "../../components/Layout/AppView";

const NewTransaction = () => {
  return (
    <AppView>
      <div>NewTransaction</div>
    </AppView>
  );
};

export default NewTransaction;
