import React, { createContext, useState } from "react";

// Create a context for authentication
const AuthContext = createContext({
  authUser: null,
  signIn: (user: { username: string; password: string }): any => {},
  signOut: () => {},
});

// AuthProvider component to wrap around the app
const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [authUser, setAuthUser] = useState<any>(null);

  // Method to sign in the user
  const signIn = (user: { username: string; password: string }) => {
    setAuthUser(user);
    return new Promise((resolve) => {
      resolve(user);
    });
  };

  // Method to sign out the user
  const signOut = () => {
    setAuthUser(null);
  };

  return (
    <AuthContext.Provider value={{ authUser, signIn, signOut }}>{children}</AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
