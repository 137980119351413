import React from "react";
import "./SearchBar.scss";

import SEARCH_IOCN_OUTLINED from "./search_outline.svg";

const SearchBar = () => {
  return (
    <div className='SearchBarContainer'>
      <span className='__icon' style={{ backgroundImage: `url(${SEARCH_IOCN_OUTLINED})` }}></span>
      <input
        type='text'
        className='SearchField'
        placeholder='Search by Customer Name, Mobile / Service / Product Name / SKU / Etc,.'
      />
    </div>
  );
};

export default SearchBar;
