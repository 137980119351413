import React from "react";

import "./AppointmentsList.scss";

const AppointmentsList = () => {
  const appointments = [];
  return (
    <div className='Appointments HomeLayoutCard vlz-12'>
      <div className='__Header'>
        <div className='__title'>Appointments</div>
        <div className='__options'></div>
      </div>
      {!!appointments.length && <div className='AppointmentsList'></div>}
      {!appointments?.length && (
        <div className='NoAppointmentsMessage'>No appontments available to display</div>
      )}
    </div>
  );
};

export default AppointmentsList;
