import React, { useState } from "react";

import "./AuthenticationPage.scss";
import VlInput from "../../components/_/Input";
import BrandLogo from "../../components/Layout/MainNavigation/fragments/BrandLogo";
import VlButton from "../../components/_/Button";
import { useNavigate } from "react-router-dom";
import {
  EMAIL_REGEX_PATTERN,
  PASSWORD_REGEX_PATTERN,
  USERNAME_EXTENTION,
  useAuth,
} from "../../util";

const AuthenticationPage = () => {
  const [username, setusername] = useState<string>("");
  const [password, setpassword] = useState<string>("");
  const { signIn } = useAuth();
  const navigate = useNavigate();

  console.log(username, password);

  const handleAuthFormSubmit = (event: any) => {
    const isValidLoginId = EMAIL_REGEX_PATTERN.test(username);
    const isValidPassword = PASSWORD_REGEX_PATTERN.test(password);
    if (!isValidPassword) return;
    signIn({ username: isValidLoginId ? username : username + USERNAME_EXTENTION, password }).then(
      (user: any) => {
        navigate("/");
      }
    );
  };

  return (
    <div className='AuthFormContainer'>
      <div className='__Form vlz-12'>
        <div className='brand'>
          <BrandLogo />
        </div>
        <h1>Hello 👋</h1>
        <legend>Please signin to continue...!</legend>
        <div className='FromGroup'>
          <VlInput
            name='username'
            valueChange={(value: string, event: any) => {
              setusername(value);
            }}
            placeholder='Username'
          />
          <VlInput
            name='password'
            type='password'
            valueChange={(value: string, event: any) => {
              setpassword(value);
            }}
            placeholder='Password'
          />
          <VlButton
            variant='contained'
            click={handleAuthFormSubmit}
            disabled={!PASSWORD_REGEX_PATTERN.test(password)}
          >
            Login
          </VlButton>
        </div>
      </div>
    </div>
  );
};

export default AuthenticationPage;
