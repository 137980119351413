import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../util";

const ProtectedRoute = (props: any) => {
  const { authUser } = useAuth();

  if (authUser) {
    return props.view;
  }

  return <Navigate to='/signin' />;
};

export default ProtectedRoute;
