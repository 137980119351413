import React from "react";
import BrandLogo from "./fragments/BrandLogo";
import MainNavLinks from "./fragments/MainNavLinks";
import MainNavLink from "./fragments/MainNavLink";

import "./MainNavigation.scss";

const MainNavigation = () => {
  return (
    <div className='MainNavigation'>
      <div className='_TopSection'>
        <BrandLogo />
        <MainNavLinks>
          <MainNavLink
            navTitle='Home'
            path={"/"}
            startIcon={<i className='bi bi-house-door'></i>}
            startActiveIcon={<i className='bi bi-house-door-fill'></i>}
            endIcon={<></>}
          />
          <MainNavLink
            path={"/new_transaction"}
            navTitle='Sale'
            startIcon={<i className='bi bi-currency-rupee'></i>}
            startActiveIcon={<i className='bi bi-currency-rupee'></i>}
          />
          <MainNavLink
            path={"/appointments"}
            navTitle='Appointments'
            startIcon={<i className='bi bi-calendar-week'></i>}
            startActiveIcon={<i className='bi bi-calendar-week-fill'></i>}
          />
          <MainNavLink
            path={"/sale_recipts"}
            navTitle='Sale Receipts'
            startIcon={<i className='bi bi-receipt-cutoff'></i>}
          />
          {/* <MainNavLink path={"/sales"}  navTitle='Inwards' startIcon={<></>} /> */}
          <MainNavLink
            path={"/customers"}
            navTitle='Customers'
            startIcon={<i className='bi bi-people'></i>}
            startActiveIcon={<i className='bi bi-people-fill'></i>}
          />
          <MainNavLink
            path={"/campaigns"}
            navTitle='Campaigns'
            startIcon={<i className='bi bi-credit-card-2-front'></i>}
            startActiveIcon={<i className='bi bi-credit-card-2-front-fill'></i>}
          />
          <MainNavLink
            path={"/reports"}
            navTitle='Reports'
            startIcon={<i className='bi bi-pass'></i>}
            startActiveIcon={<i className='bi bi-pass-fill'></i>}
          />
          <MainNavLink
            path={"/admin"}
            navTitle='Admin'
            startIcon={<i className='bi bi-person-gear'></i>}
            startActiveIcon={<i className='bi bi-person-fill-gear'></i>}
          />
        </MainNavLinks>
      </div>
      <div className='_MiddleSection'></div>
      <div className='_BottomSection'>
        <MainNavLink
          path={"/signin"}
          navTitle='Signout'
          onClick={() => {}}
          startIcon={<i className='bi bi-box-arrow-left'></i>}
          end
        />
      </div>
    </div>
  );
};

export default MainNavigation;
