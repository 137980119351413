import React from "react";

import "./Input.scss";

const VlInput = ({ valueChange, className, name, ...rest }: any) => {
  const handleChange = (event: any) => {
    if (valueChange) valueChange(event.target.value, event);
  };

  return (
    <div className='VlInput'>
      <input
        type='text'
        placeholder={`${name || "Please enter a value..."}`}
        {...rest}
        className={`__Input ${className || ""}`}
        onChange={handleChange}
      />
    </div>
  );
};

export default VlInput;
