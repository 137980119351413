import React from "react";
import "./styles.scss";
import MainNavigation from "../MainNavigation/MainNavigation";
import Page from "../Page/Page";

const AppView = (props: any) => {
  return (
    <div className='AppSkeleton'>
      <div className='MainNavigationBox vlz-4'>
        <MainNavigation />
      </div>
      <div className='MainPageContentBox'>
        <Page>{props.children}</Page>
      </div>
    </div>
  );
};

export default AppView;
