import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import HomePage from "../pages/Home/index";
import NewTransaction from "../pages/NewTransaction/index";
import TransactionsPage from "../pages/Transactions/index";
import CustomersPage from "../pages/Customers/index";
import CampaignsPage from "../pages/Campaigns/index";
import ReportsPage from "../pages/Reports/index";
import AdminPage from "../pages/Admin/index";
import AuthenticationPage from "../pages/Authentication/index";
import AppointmentsPage from "../pages/Appointments/index";

const protectedPages = [
  {
    path: "",
    element: <HomePage />,
  },
  {
    path: "new_transaction",
    element: <NewTransaction />,
  },
  {
    path: "appointments",
    element: <AppointmentsPage />,
  },
  {
    path: "sale_recipts",
    element: <TransactionsPage />,
  },
  {
    path: "customers",
    element: <CustomersPage />,
  },
  {
    path: "campaigns",
    element: <CampaignsPage />,
  },
  {
    path: "reports",
    element: <ReportsPage />,
  },
  {
    path: "admin",
    element: <AdminPage />,
  },
];

const router = createBrowserRouter([
  ...protectedPages.map(
    (page) =>
      ({
        path: page.path,
        element: <ProtectedRoute view={page.element} />,
      } as any)
  ),
  {
    path: "signin",
    element: <AuthenticationPage />,
  },
]);

const Router = (props: any) => {
  return <RouterProvider router={router} />;
};

export default Router;
