import React from "react";
import { useNavigate } from "react-router-dom";

import "./QuickActionsGrid.scss";
import QAL from "../QAL/index";

const QuickActionsGrid = () => {
  const navigate = useNavigate();
  const quickActions = [
    {
      title: "Sale",
      icon: <i className='bi bi-receipt-cutoff'></i>,
      action: () => {
        navigate("/new_transaction");
      },
    },
    {
      title: "Scan UPC",
      icon: <i className='bi bi-upc-scan'></i>,
      action: () => console.log("Scan UPC"),
    },
    {
      title: "Inwards",
      icon: <i className='bi bi-download'></i>,
      action: () => console.log("Inwards"),
    },
    {
      title: "Reports",
      icon: <i className='bi bi-pass'></i>,
      action: () => {
        navigate("/reports");
      },
    },
    {
      title: "Appointments",
      icon: <i className='bi bi-calendar-week'></i>,
      action: () => {
        navigate("/appointments");
      },
    },
    {
      title: "Inventory",
      icon: <i className='bi bi-dropbox'></i>,
      action: () => console.log("Inventory"),
    },
  ];
  return (
    <div className='QuickActionsGrid'>
      {quickActions.map((quickAction, index) => (
        <QAL
          key={quickAction.title + index}
          title={quickAction.title}
          icon={quickAction.icon}
          action={quickAction.action}
        />
      ))}
    </div>
  );
};

export default QuickActionsGrid;
