import React from "react";

const BrandLogo = () => {
  return (
    <div className='BrandLogo'>
      <img src='/assets/img/logo.svg' alt='brand_logo' />
    </div>
  );
};

export default BrandLogo;
