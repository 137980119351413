import React from "react";

import PERSON_FILL from "./person-fill.svg";

import "./AppHeader.scss";
import SearchBar from "../../SearchBar";

const AppHeader = () => {
  return (
    <div className='DefaultHeaderContainer'>
      <div className='SearchBarBox'>
        <SearchBar />
      </div>
      <div className='ProfileInfoBox'>
        <div className='__info'>
          Harindra, Masna [VL-HYT] <i className='bi bi-chevron-down'></i>
        </div>
        <div className='__Avatar' style={{ backgroundImage: `url(${PERSON_FILL})` }}></div>
      </div>
    </div>
  );
};

export default AppHeader;
