import React from "react";
import { NavLink } from "react-router-dom";

const MainNavLink = (props: any) => {
  const handleOnClick = (e: any) => {
    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <NavLink
      to={props.path}
      className={(isActive) => {
        const className = "_NavLink";
        return isActive.isActive ? `${className} _Active` : className;
      }}
      {...(props.end ? { end: props.end } : {})}
      onClick={handleOnClick}
    >
      <div className='__base'>
        <span className='nav_icon'>
          <span className='__default'>{props?.startIcon || <></>}</span>
          <span className='__active'>{props?.startActiveIcon || props?.startIcon || <></>}</span>
        </span>
        <span className='__title'>{props.navTitle}</span>
      </div>
    </NavLink>
  );
};

export default MainNavLink;
