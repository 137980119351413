import React from "react";

import "./QuickActionLink.scss";

/* 
  <QuickActionLink
      key={quickAction.title + index}
      title={quickAction.title}
      icon={quickAction.icon}
      action={quickAction.action}
  /> 
*/

const QAL = (props: any) => {
  const handleActionClick = () => {
    if (props.action) {
      props.action();
    }
  };
  return (
    <div className='QuickActionLink' onClick={handleActionClick}>
      <span className='__Icon'>{props.icon}</span>
      <span className='__Title'>{props.title}</span>
    </div>
  );
};

export default QAL;
